<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{$t('tradeTariffReport.import_sepcific_report')}} {{$t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
          <b-row>
            <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="Importer Country" vid="country_id" rules="required|min_value:1" v-slot="{ valid, errors }">
                <b-form-group
                  label-for="country_id"
                >
                <template v-slot:label>
                  {{ $t('tradeTariffReport.importer_country')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  v-model="search.country_id"
                  :options="tradeTariffCountryList"
                  id="country_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <ValidationProvider name="From Date" vid="from_date" rules="" v-slot="{ valid, errors }">
                <b-form-group
                  label-for="from_date">
                  <template v-slot:label>
                    {{ $t('globalTrans.from_date') }}
                  </template>
                  <date-picker
                    id="from_date"
                    v-model="search.from_date"
                    class="form-control"
                    :placeholder="$t('globalTrans.select_date')"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :class="errors[0] ? 'is-invalid' : ''"
                  >
                  </date-picker>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <ValidationProvider name="To Date" vid="to_date" rules="" v-slot="{ valid, errors }">
                <b-form-group
                  label-for="to_date">
                  <template v-slot:label>
                    {{ $t('globalTrans.to_date') }}
                  </template>
                  <date-picker
                    id="to_date"
                    v-model="search.to_date"
                    class="form-control"
                    :placeholder="$t('globalTrans.select_date')"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :class="errors[0] ? 'is-invalid' : ''"
                  >
                  </date-picker>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
              <b-col sm="12" style="text-align: right;">
                <b-button size="sm" type="submit" variant="primary">
                  <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                </b-button>
              </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('tradeTariffReport.import_sepcific_report') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
        <div>
          <b-row class="text-right mt-2">
            <b-col>
              <b-button v-if="itemList.length" @click="pdfExport" class="btn btn-success btn-md">
                <i class="ri-file-pdf-line"></i>  {{  $t('globalTrans.pdf') }}
              </b-button>
            </b-col>
          </b-row>
          <div class="table-wrapper table-responsive" >
            <ReportHeading />
            <table class="table table-striped table-hover table-bordered">
            <tr style="border:none;">
              <th style="border:none;" class="text-left">{{$t('tradeTariffReport.importer_country')}} : {{ search.country_id ? getCountryName(search.country_id) : $t('globalTrans.all') }}</th>
              <th style="border:none;" class="text-center">{{$t('globalTrans.from_date')}} : {{ search.from_date ? dateFormat(search.from_date) : $t('globalTrans.all') }} </th>
              <th style="border:none;" class="text-right">{{$t('globalTrans.to_date')}} : {{ search.to_date ? dateFormat(search.to_date) : $t('globalTrans.all') }}</th>
            </tr>
            <tr>
              <th colspan="15" style="text-align: center;">{{ $t('tradeTariffReport.import_sepcific_report') }}</th>
            </tr>
            <tr>
              <th class="text-center">{{$t('globalTrans.sl_no')}}</th>
              <th class="text-center">{{$t('globalTrans.country_name')}}</th>
              <th class="text-center">{{$t('tradeTariffReport.value_usd')}}</th>
            </tr>
              <template v-if="itemList && itemList.length > 0">
                <tr v-for="(item,index) in itemList" :key="index">
                  <td class="text-center">{{ $n(index + 1) }}</td>
                  <td class="text-center">{{ getCountryName(item.country_id) }}</td>
                  <td class="text-center">{{ $n(item.import_amount) }}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="13" align="center">{{ $t('globalTrans.noDataFound') }}</td>
                </tr>
              </template>
          </table>
          </div>
        </div>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<script>
import ReportHeading from '../../pages/report-heading/ReportHeading.vue'
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { importerSpecificCountryReport } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import { dateFormat } from '@/utils/fliter'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    ReportHeading
  },
  data () {
    return {
      dateFormat: dateFormat,
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      search: {
        from_date: '',
        to_date: '',
        report_type_id: 0,
        country_id: 0,
        limit: 10
      },
      viewitemId: 0,
      itemList: []
    }
  },
  created () {
    // this.loadData()
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    tradeTariffCountryList: function () {
      return this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.filter(item => item.status === 1)
    },
    viewTitle () {
      return this.$t('tradeTariffReport.hs_code') + ' ' + this.$t('globalTrans.details')
    }
  },
  watch: {
    'search.section_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.chapterList = this.getChapterList(newVal)
      } else {
        this.chapterList = []
      }
    }
  },
  methods: {
    getCountryName (id) {
      const obj = this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.find(item => item.value === parseInt(id))
      if (obj) {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      } else {
        return ''
      }
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.countries = []
      this.exportAmount = []
      this.itemList = []
      this.searchType = 0
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(tradeTariffServiceBaseUrl, importerSpecificCountryReport, params)
      if (result.success) {
        this.itemList = result.data
      } else {
        this.itemList = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async pdfExport () {
        const params = Object.assign({}, this.search, { request_type: 'pdf', local: this.$i18n.locale, org_id: 7 })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(tradeTariffServiceBaseUrl, importerSpecificCountryReport, params)
        var blob = new Blob([result], {
            type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
